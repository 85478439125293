<template>
  <b-modal id="docsUpload"
           lazy
           no-fade
           size="lg"
           :no-close-on-backdrop="loading && isBrazil"
           :no-close-on-esc="loading && isBrazil"
           :hide-header-close="loading && isBrazil"
           :hide-footer="true"
           :title="$t('drivers.labels.deliveryCars')"
           @hide="handleClose"
           @show="handleShow">
    <driver-header v-if="!!booking.driver.id"
                   :id="booking.driver.id"
                   :reset-profile="false"
    />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            {{ booking.plan.name }}
            <br>
            <div v-if="booking.car.fuel.length">
              <strong v-for="(obj, index) in booking.car.fuel" :key="index">
                {{ getFuelName(obj) }}
                <span>/</span>
              </strong>
            </div>
          </div>

          <div class="col-8">
            {{ $t('drivers.labels.board') }}
            <br>
            <strong>
              {{ booking.car.license_plate }}
            </strong>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-4">
            <h5>
              <strong>
                {{ $t('drivers.labels.docsLocation') }}
              </strong>
            </h5>
          </div>
        </div>

        <b-row v-if="loading">
          <b-col>
            <b-alert :show="loading && isBrazil" variant="warning">
              <h4>
                Estamos analisando as assinaturas, aguarde ...
              </h4>
            </b-alert>
          </b-col>
        </b-row>
        <b-row v-if="signatureApprovedByIa">
          <b-col>
            <b-alert :show="isBrazil" variant="success">
              <h4>
                A assinatura já foi aprovada!
              </h4>
            </b-alert>
          </b-col>
        </b-row>

        <div class="row">
          <div v-for="(item, index) in images"
               :key="index"
               :class="isBrazil ? 'col-4' : 'col-6 mb-2'">
            <new-upload-image :label="item.label"
                              :caption="item.caption"
                              :item="item"
                              :key-item="item.keyItem"
                              :file-upload-endpoint="item.endpoint"
                              :file-upload-props="item.uploadProps"
                              @imageUpload="onImageUpload($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <b-row>
      <b-col class="text-center mb-4">
        <b-button id="driver-view-cancel-booking"
                  class="font-weight-bold"
                  variant="danger"
                  @click="handleClose"
                  :disabled="!enableSignatureCompare || loading"
        >
          {{ $t('modalBooking.words.close') }}
        </b-button>
        <b-button v-if="loading && isBrazil" id="driver-view-activate-booking"
                  class="font-weight-bold ml-3"
                  variant="success"
                  :disabled="!enableSignatureCompare || !signatureMatched"
                  @click="handleClose"
        >
          <template>
            <b-spinner small class="mr-2" />
            Analisando Assinaturas
          </template>
        </b-button>
      </b-col>
    </b-row>

    <modal-fleet-compare-signatures ref="fleetCompareSignatures"
                                    :driver-document-url="getDriverDocumentUrl"
                                    :contract-url="getContractUrl"
                                    :booking-id="booking.id"
                                    :driver-id="booking.driver.id"
                                    :can-open-modal-on-load="false"
    />
  </b-modal>
</template>

<script>
import DriverHeader from '@components/drivers/header';
import NewUploadImage from '@components/shared/NewUploadImage';
import { mapGetters } from 'vuex';
import { isBrazil as isBrazilHelper } from '@utils/helper-region';
import ModalFleetCompareSignatures from '@components/fleet/modals/fleet-compare-signatures';

export default {
  name: 'ModalDocsUpload',

  components: {
    ModalFleetCompareSignatures,
    DriverHeader,
    NewUploadImage,
  },
  data() {
    return {
      signatureMatched: false,
      loading: false,
      signatureApprovedByIa: false,
      booking: {
        car: {
          fuel: [],
          license_plate: '',
        },
        contract: '',
        driver: {
          id: '',
        },
        id: '',
        plan: {
          name: '',
        },
      },
      addressProof: null,
    };
  },
  computed: {
    ...mapGetters({
      bookingSelect: 'driver/bookingSelect',
      driverDocuments: 'driverDocument/address',
    }),
    isBrazil() {
      return isBrazilHelper();
    },
    images() {
      let source = null;
      if (this.bookingSelect?.id) {
        source = `${this.bookingSelect?.id}_${this.getCurrentTimestamp()}`;
      }
      const uploadImagesProps = {
        contrato: {
          label: 'contrato',
          keyItem: 'contrato',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.contract || null,
          endpoint: '/docs/upload-generic',
          uploadProps: {
            type: 'contract',
            source,
            fileKey: 'file',
          },
        },
        cnh: {
          label: this.$t('drivers.labels.license_number'),
          keyItem: 'cnh',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.driver?.cnh_doc?.url || null,
          endpoint: '/docs',
          accept: 'image/*,.pdf',
          uploadProps: {
            documentType: 'cnh_photo',
            driverId: this.bookingSelect?.driver?.id || null,
            fileKey: 'data',
          },
        },
        crlv: {
          label: 'crlv',
          keyItem: 'crlv',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.car?.document_url || null,
          endpoint: '/docs/upload-generic',
          uploadProps: {
            type: 'car_document',
            source: this.bookingSelect?.car?.id || null,
            fileKey: 'file',
          },
        },
        address: {
          label: this.$t('drivers.labels.addressProof'),
          keyItem: 'address_photo',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.addressProof?.url || null,
          endpoint: '/docs',
          accept: 'image/*,.pdf',
          uploadProps: {
            documentType: 'address_photo',
            driverId: this.bookingSelect?.driver?.id || null,
            fileKey: 'data',
          },
        },
      };

      if (this.isBrazil) delete uploadImagesProps.crlv;

      return uploadImagesProps;
    },

    enableSignatureCompare() {
      const isEnabled = !!(this.bookingSelect?.driver?.cnh_doc?.url) && !!(this.bookingSelect?.contract);
      // this.loading = !isEnabled || !this.signatureMatched
      return isEnabled
    },
    getDriverDocumentUrl() {
      if (!this.bookingSelect?.driver?.cnh_doc?.url) return null
      return `${this.bookingSelect?.driver?.cnh_doc?.url}?t${+new Date()}`;
    },
    getContractUrl() {
      if (!this.bookingSelect?.contract) return null
      return `${this.bookingSelect?.contract}?t${+new Date()}`;
    },
  },
  mounted() {
    const { contract } = this.$route?.params;
    if (contract) this.loading = true
    this.$root.$on('signatureMatched', this.handleSignatureMatched)
    this.addressProof = this.driverDocuments?.find(item => item.type === 'ADDRESS')
  },
  methods: {
    handleSignatureMatched(val){
      this.signatureApprovedByIa = val
      if (!val) {
        this.$root.$emit('bv::show::modal', 'fleetCompareSignatures')
        this.loading = false
        return
      }
      this.loading = false
      this.signatureMatched = true
    },
    handleShow() {
      this.booking = this.bookingSelect
    },
    getCurrentTimestamp() {
      const now = new Date();
      const pad = num => String(num).padStart(2, '0');
      return `${now.getFullYear()}${pad(now.getMonth() + 1)}${pad(now.getDate())}${pad(now.getHours())}${pad(now.getMinutes())}${pad(now.getSeconds())}`;
    },
    handleClose() {
      this.$root.$emit('bv::hide::modal', 'docsUpload');
      this.$root.$emit('bv::hide::modal', 'modalBooking');
      this.$root.$emit('bv::hide::modal', 'fleetCompareSignatures');
      window.location.reload();
    },
    onImageUpload($event) {
      if ($event.data?.updateBookingContract?.contract) {
        this.bookingSelect.contract = $event.data?.updateBookingContract?.contract;
      }
      if ($event.data?.updateDriverLicense?.cnh_doc?.url) {
        this.bookingSelect.driver.cnh_doc.url = $event.data?.updateDriverLicense?.cnh_doc?.url
      }
      this.images[$event.key].caption = $event.msg;
      if (
        this.bookingSelect?.contract && this.bookingSelect?.driver?.cnh_doc?.url
        && ['contrato', 'cnh'].includes($event.key)
        && $event.msg.includes('Atualizado')
        && this.isBrazil
      ) {
        this.loading = true
        setTimeout(() => {
          this.signatureMatched = false
          this.$refs.fleetCompareSignatures.handleSignatureCompare($event.imageDefault, $event.key)
        }, 500)
      }
    },
    getFuelName(fuel) {
      return fuel?.name || '';
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  &.close-btn {
    min-width: 135px;
  }
}
</style>
