var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalBooking",
        lazy: "",
        "no-fade": "",
        "no-close-on-backdrop": _vm.block_ui,
        "no-close-on-esc": _vm.block_ui,
        "hide-header-close": _vm.block_ui,
        size: "lg",
        title: _vm.modal.title,
      },
      on: { show: _vm.handleOpen, hide: _vm.handleClose },
    },
    [
      _c(
        "b-container",
        [
          _vm.$apollo.queries.booking_options.loading
            ? _c("content-loading")
            : _vm._e(),
          !_vm.$apollo.queries.booking_options.loading
            ? _c(
                "b-form",
                { attrs: { name: "booking" } },
                [
                  !_vm.isRegionMexico()
                    ? _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                [
                                  _vm.driverScheduleDelivery
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                title: _vm.$i18n.t(
                                                  "modalBooking.texts.scheduledDeliveryResume"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-card-text", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalBooking.texts.scheduledDelivery.kind"
                                                      )
                                                    ) + ":"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalSchedule.type." +
                                                          _vm
                                                            .driverScheduleDelivery
                                                            .type
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                              ]),
                                              _c("b-card-text", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalBooking.texts.scheduledDelivery.comments"
                                                      )
                                                    ) + ":"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.driverScheduleDelivery
                                                        .comments
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                              ]),
                                              _c("b-card-text", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalBooking.texts.scheduledDelivery.place"
                                                      )
                                                    ) + ": "
                                                  ),
                                                ]),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.schedulePlace.address
                                                        .neighborhood
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.schedulePlace.name
                                                    ) +
                                                    ") (" +
                                                    _vm._s(
                                                      _vm.schedulePlace.address
                                                        .street
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.schedulePlace.address
                                                        .number
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.schedulePlace.address
                                                        .neighborhood
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.schedulePlace.address
                                                        .city
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.schedulePlace.address
                                                        .state
                                                    ) +
                                                    ") "
                                                ),
                                              ]),
                                              _c("b-card-text", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalBooking.texts.scheduledDelivery.date"
                                                      )
                                                    ) + ": "
                                                  ),
                                                ]),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.dateFormat(
                                                        _vm
                                                          .driverScheduleDelivery
                                                          .scheduled_at
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("b-card-text", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalBooking.texts.scheduledDelivery.status"
                                                      )
                                                    ) + ": "
                                                  ),
                                                ]),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalBooking.texts.scheduledDeliveryStatuses." +
                                                          _vm
                                                            .driverScheduleDelivery
                                                            .status
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-card",
                                        [
                                          _c("b-card-text", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$i18n.t(
                                                    "modalBooking.texts.driverHasNotScheduledDelivery"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm.data.status !== "PENDING" &&
                      _vm.data.status !== "ACTIVE" &&
                      _vm.data.status !== "CLOSED" &&
                      !_vm.isRegionMexico()
                        ? _c(
                            "b-form-row",
                            [
                              _c("last-booking-resume", {
                                attrs: {
                                  "driver-id": _vm.$route.params.id,
                                  region: _vm.region,
                                  "use-last-plan": !(
                                    _vm.block_ui ||
                                    _vm.block_active ||
                                    _vm.block_closed
                                  ),
                                },
                                on: {
                                  selected: (event) =>
                                    _vm.updateBookingOptions(event, true),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !["PENDING", "CLOSED", "CANCELED"].includes(
                        _vm.data.status
                      ) && _vm.isRegionBrazil()
                        ? _c("license-info-edit", {
                            attrs: {
                              "enable-disable-save-button":
                                _vm.enableDisableSaveButton,
                              "driver-data": _vm.driverData,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "b-card",
                        [
                          _c(
                            "b-form-row",
                            { staticClass: "align-items-center" },
                            [
                              _c("b-form-group", {
                                staticClass: "col-12 required",
                                attrs: {
                                  label: _vm.$i18n.t(
                                    "modalBooking.words.planFilter"
                                  ),
                                  "label-for": "booking-plan",
                                  required: "",
                                },
                              }),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-3 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.planFilterStatus"
                                    ),
                                    "label-for": "booking-plan",
                                    required: "",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "booking-plan-status",
                                      state: _vm.checkField(
                                        _vm.planStatusFilters
                                      ),
                                      disabled:
                                        _vm.block_ui ||
                                        _vm.block_pending ||
                                        _vm.block_active ||
                                        _vm.block_closed ||
                                        _vm.selectPlanDisabled,
                                      options: _vm.plan_status_filters,
                                    },
                                    on: { change: _vm.changePlanStatusFilters },
                                    model: {
                                      value: _vm.planStatusFilters,
                                      callback: function ($$v) {
                                        _vm.planStatusFilters = $$v
                                      },
                                      expression: "planStatusFilters",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-3 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.planFilterProduct"
                                    ),
                                    "label-for": "booking-plan",
                                    required: "",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "booking-plan-product-type",
                                      state: _vm.checkField(
                                        _vm.planProductTypeFilters
                                      ),
                                      disabled:
                                        _vm.block_ui ||
                                        _vm.block_pending ||
                                        _vm.block_active ||
                                        _vm.block_closed ||
                                        _vm.selectPlanDisabled,
                                      options: _vm.plan_products_types_filters,
                                    },
                                    on: {
                                      change: _vm.changePlanProductTypeFilters,
                                    },
                                    model: {
                                      value: _vm.planProductTypeFilters,
                                      callback: function ($$v) {
                                        _vm.planProductTypeFilters = $$v
                                      },
                                      expression: "planProductTypeFilters",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-3 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.planFilterCategory"
                                    ),
                                    "label-for": "booking-plan",
                                    required: "",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "booking-plan-category",
                                      state: _vm.checkField(
                                        _vm.planCategoryFilters
                                      ),
                                      disabled:
                                        _vm.block_ui ||
                                        _vm.block_pending ||
                                        _vm.block_active ||
                                        _vm.block_closed ||
                                        _vm.selectPlanDisabled,
                                      options: _vm.plan_categories_filters,
                                    },
                                    on: {
                                      change: _vm.changePlanCategoryFilters,
                                    },
                                    model: {
                                      value: _vm.planCategoryFilters,
                                      callback: function ($$v) {
                                        _vm.planCategoryFilters = $$v
                                      },
                                      expression: "planCategoryFilters",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-3 col-sm-12 pt-3",
                                  attrs: {
                                    "label-for": "booking-plan",
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      ref: "street_no_number",
                                      staticClass: "mt-1",
                                      attrs: {
                                        disabled:
                                          _vm.block_pending ||
                                          _vm.block_active ||
                                          _vm.block_closed,
                                      },
                                      model: {
                                        value: _vm.isZeroKmFilter,
                                        callback: function ($$v) {
                                          _vm.isZeroKmFilter = $$v
                                        },
                                        expression: "isZeroKmFilter",
                                      },
                                    },
                                    [_vm._v(" Somente 0KM ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-12 required",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.plan"
                                    ),
                                    "label-for": "booking-plan",
                                    required: "",
                                  },
                                },
                                [
                                  _c("input-select-search", {
                                    attrs: {
                                      id: "booking-plan",
                                      "value-field": "id",
                                      name: "booking-plan",
                                      placeholder: "Selecione um Plano",
                                      options: _vm.planOptions,
                                      state: _vm.checkField(_vm.data.plan),
                                      model: _vm.planFiltered.nameFormated,
                                      disabled:
                                        _vm.block_ui ||
                                        _vm.block_pending ||
                                        _vm.block_active ||
                                        _vm.block_closed ||
                                        _vm.selectPlanDisabled,
                                    },
                                    on: { selected: _vm.updateBookingOptions },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$i18n.t(
                                              "modalBooking.texts.fieldRequired"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-3 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.texts.startDate"
                                    ),
                                    "label-for": "booking-started",
                                    required: "",
                                  },
                                },
                                [
                                  _c("date-picker", {
                                    attrs: {
                                      id: "booking-started",
                                      "not-before": _vm
                                        .$moment()
                                        .toDate()
                                        .toString(),
                                      "field-state": _vm.checkField(
                                        _vm.data.started_at
                                      ),
                                      hide:
                                        _vm.block_ui ||
                                        _vm.block_active ||
                                        _vm.block_closed,
                                    },
                                    model: {
                                      value: _vm.data.started_at,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "started_at", $$v)
                                      },
                                      expression: "data.started_at",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-3 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.texts.endDate"
                                    ),
                                    "label-for": "booking-finished",
                                    required: "",
                                  },
                                },
                                [
                                  _c("date-picker", {
                                    attrs: {
                                      id: "booking-finished",
                                      "not-before": _vm
                                        .$moment()
                                        .toDate()
                                        .toString(),
                                      hide:
                                        _vm.block_ui ||
                                        !_vm.block_active ||
                                        _vm.block_closed,
                                    },
                                    model: {
                                      value: _vm.data.finished_at,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "finished_at", $$v)
                                      },
                                      expression: "data.finished_at",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isRegionBrazil()
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass:
                                        "col-md-6 col-sm-12 required",
                                      attrs: {
                                        label: _vm.$i18n.t(
                                          "modalBooking.words.openKind"
                                        ),
                                        "label-for": "booking-open-kind",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        class:
                                          _vm.isValidOpenKind &&
                                          _vm.warnOpenKindMessage
                                            ? "warn-border"
                                            : "",
                                        attrs: {
                                          id: "booking-open-kind",
                                          disabled:
                                            _vm.block_ui ||
                                            _vm.block_active ||
                                            _vm.block_closed ||
                                            _vm.block_pending,
                                          options: this.openKindOptions,
                                          required: _vm.isRegionBrazil(),
                                          "value-field": "value",
                                          state: _vm.isValidOpenKind,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onOpenKindChange($event)
                                          },
                                        },
                                        model: {
                                          value: _vm.data.kind_opened_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "kind_opened_id",
                                              $$v
                                            )
                                          },
                                          expression: "data.kind_opened_id",
                                        },
                                      }),
                                      _c("div", { staticClass: "mt-3" }, [
                                        _c(
                                          "small",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.openingKindRule.length >
                                                  0,
                                                expression:
                                                  "openingKindRule.length > 0",
                                              },
                                            ],
                                          },
                                          [
                                            _c("h6", [
                                              _vm._v(
                                                "Regras do motivo selecionado"
                                              ),
                                            ]),
                                            _c(
                                              "b-list-group",
                                              _vm._l(
                                                _vm.openingKindRule,
                                                function (item, index) {
                                                  return _c(
                                                    "b-list-group-item",
                                                    { key: index },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$i18n.t(
                                                              "modalBooking.texts.openingRules." +
                                                                item.key
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        ": " +
                                                          _vm._s(item.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "small",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.kindRuleLoading,
                                              expression: "kindRuleLoading",
                                            },
                                          ],
                                        },
                                        [_vm._v("Carregando regras...")]
                                      ),
                                      _vm.isValidOpenKind &&
                                      _vm.warnOpenKindMessage
                                        ? _c(
                                            "div",
                                            { staticClass: "pt-2 warn-label" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.warnOpenKindMessage
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$i18n.t(
                                                  "modalBooking.texts.openKindFieldInvalid"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-4 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.texts.securityDeposit"
                                    ),
                                    "label-for": "booking-pre-method",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "booking-pre-method",
                                      state: _vm.checkField(
                                        _vm.data.pre_transaction_method
                                      ),
                                      disabled:
                                        _vm.block_ui ||
                                        _vm.block_active ||
                                        _vm.block_closed ||
                                        _vm.isPlanFromCheckboxOption,
                                      options: _vm.options_pre_payment_methods,
                                    },
                                    on: { change: _vm.setPaymentMethod },
                                    model: {
                                      value: _vm.data.pre_transaction_method,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "pre_transaction_method",
                                          $$v
                                        )
                                      },
                                      expression: "data.pre_transaction_method",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$i18n.t(
                                              "modalBooking.texts.fieldRequired"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-2 col-sm-12 required",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.installments"
                                    ),
                                    "label-for": "booking-pre-installments",
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.right.hover.v-warning",
                                          modifiers: {
                                            right: true,
                                            hover: true,
                                            "v-warning": true,
                                          },
                                        },
                                      ],
                                      attrs: {
                                        title: _vm.tooltipText,
                                        disabled:
                                          _vm.disableInstallmentTooltipText,
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "booking-pre-installments",
                                          disabled:
                                            _vm.block_ui ||
                                            _vm.block_installments ||
                                            _vm.block_active ||
                                            _vm.block_closed ||
                                            _vm.isPlanFromCheckboxOption,
                                          state: _vm.checkField(
                                            _vm.data
                                              .pre_transaction_installments
                                          ),
                                          options:
                                            _vm.options_pre_transaction_installments,
                                        },
                                        model: {
                                          value:
                                            _vm.data
                                              .pre_transaction_installments,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "pre_transaction_installments",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.pre_transaction_installments",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$i18n.t(
                                              "modalBooking.texts.fieldRequired"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-2 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.discount"
                                    ),
                                    "label-for": "booking-pre-discount",
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "booking-pre-discount",
                                          readonly: "",
                                          maxlength: "2",
                                          type: "text",
                                        },
                                        model: {
                                          value:
                                            _vm.data.pre_transaction_discount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "pre_transaction_discount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.pre_transaction_discount",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-text",
                                        {
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [_vm._v(" % ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-4 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.texts.recurrencyRentPayment"
                                    ),
                                    "label-for": "booking-invoice-method",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "booking-invoice-method",
                                      state: _vm.checkField(
                                        _vm.data.transaction_method
                                      ),
                                      required: _vm.isRegionBrazil(),
                                      disabled:
                                        _vm.block_ui || _vm.block_closed,
                                      options:
                                        _vm.options_frist_payment_methods,
                                    },
                                    model: {
                                      value: _vm.data.transaction_method,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "transaction_method",
                                          $$v
                                        )
                                      },
                                      expression: "data.transaction_method",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$i18n.t(
                                              "modalBooking.texts.fieldRequired"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.data.status === "PENDING" ||
                      _vm.data.status === "ACTIVE" ||
                      _vm.data.status === "CLOSED"
                        ? _c(
                            "b-card",
                            [
                              _c(
                                "b-form-row",
                                [
                                  _c("b-form-group", {
                                    staticClass: "col-12 required",
                                    attrs: {
                                      label: _vm.$i18n.t(
                                        "modalBooking.words.carFilter"
                                      ),
                                      "label-for": "booking-car",
                                      required: "",
                                    },
                                  }),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "col-md-3 col-sm-12",
                                      attrs: {
                                        label: _vm.$i18n.t(
                                          "modalBooking.words.category"
                                        ),
                                        "label-for": "booking-car",
                                        required: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-select",
                                        {
                                          attrs: {
                                            options:
                                              _vm.plan_categories_filters,
                                            disabled:
                                              _vm.block_ui ||
                                              _vm.block_active ||
                                              _vm.block_closed ||
                                              !_vm.can_change_car_category,
                                          },
                                          model: {
                                            value: _vm.carCategory,
                                            callback: function ($$v) {
                                              _vm.carCategory = $$v
                                            },
                                            expression: "carCategory",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "first" }, [
                                            _c(
                                              "option",
                                              {
                                                attrs: { disabled: "" },
                                                domProps: { value: null },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "modalBooking.words.selectCategory"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "col-md-9 col-sm-12 mb-0",
                                      attrs: {
                                        label: _vm.$i18n.t(
                                          "modalBooking.words.car"
                                        ),
                                        "label-for": "booking-car",
                                      },
                                    },
                                    [
                                      _vm.data.status === "ACTIVE" ||
                                      _vm.data.status === "CLOSED" ||
                                      _vm.modal_data.active_car
                                        ? _c(
                                            "div",
                                            [
                                              _c("b-input", {
                                                attrs: {
                                                  readonly: "",
                                                  value:
                                                    _vm.modal_data.active_car,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("car-by-category-select", {
                                                attrs: {
                                                  id: "booking-car",
                                                  category: _vm.carCategory,
                                                  "plan-id": _vm.data.plan,
                                                  "plan-product-type":
                                                    _vm.planFiltered
                                                      ?.product_type,
                                                  "plan-category":
                                                    _vm.planFiltered?.category,
                                                  plan: _vm.planFiltered,
                                                  "kind-opened": _vm.kindOpened,
                                                  "driver-work-city":
                                                    _vm.driverData.work_city,
                                                  disabled:
                                                    _vm.block_ui ||
                                                    _vm.block_active ||
                                                    _vm.block_closed ||
                                                    _vm.modal_data.car,
                                                },
                                                model: {
                                                  value: _vm.data.car,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "car",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "data.car",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                  _vm.data.status === "PENDING"
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-sm-3 mt-md-0",
                                          attrs: { md: "12", sm: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            { attrs: { label: "" } },
                                            [
                                              !_vm.modal_data.car
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "w-100",
                                                      attrs: {
                                                        id: "driver-view-link-car-button",
                                                        variant: "success",
                                                        size: "lg",
                                                        disabled: !_vm.data.car,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.$_confirmLinkCar,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$i18n.t(
                                                              "modalVehicleDelivery.words.link"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.modal_data.car
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "mt-3 w-100",
                                                      attrs: {
                                                        id: "driver-view-link-generate-contract",
                                                        variant: "primary",
                                                        size: "lg",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.$_genContract,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$i18n.t(
                                                              "modalVehicleDelivery.texts.printContract"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.modal_data.car
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mt-3 w-100 white-color",
                                                      attrs: {
                                                        id: "driver-view-link-generate-contract",
                                                        variant: "warning",
                                                        size: "lg",
                                                      },
                                                      on: {
                                                        click: () =>
                                                          _vm.unlinkCar(),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$i18n.t(
                                                              "modalVehicleDelivery.texts.unlinkCar"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.modal_data.kind_closed_id &&
                      _vm.data.status === "CLOSED"
                        ? _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-12 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.reasonForClosing"
                                    ),
                                    "label-for": "booking-reason-for-closing",
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "booking-reason-for-closing",
                                          value:
                                            _vm.kindClosedTranslate.toUpperCase(),
                                          readonly: "",
                                          type: "text",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !!_vm.modal_data.kind_opened_id && _vm.kindOpenedTranslate
                        ? _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-12 col-sm-12",
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "modalBooking.words.openKind"
                                    ),
                                    "label-for": "booking-reason-for-open",
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "booking-reason-for-opening",
                                          value:
                                            _vm.kindOpenedTranslate.toUpperCase(),
                                          readonly: "",
                                          type: "text",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.status === "ACTIVE" ||
                      _vm.data.status === "CLOSED" ||
                      _vm.data.status === "CANCELED"
                        ? _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  staticClass: "col-md-5 col-sm-12",
                                  attrs: { "label-for": "booking-recurrency" },
                                },
                                [
                                  _vm.data.car
                                    ? _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "mt-1",
                                          attrs: {
                                            id: "booking-recurrency",
                                            disabled: _vm.block_ui,
                                          },
                                          model: {
                                            value: _vm.data.has_recurrency,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "has_recurrency",
                                                $$v
                                              )
                                            },
                                            expression: "data.has_recurrency",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$i18n.t(
                                                  "modalBooking.texts.recurrencyEnable"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isRegionBrazil() && _vm.data.status === "PENDING"
                        ? _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 mb-4" },
                                  [
                                    _c("b-card-text", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "drivers.labels.docsLocation"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _vm.getBookingSelectId &&
                              _vm.modal.save_button ===
                                _vm.$i18n.t("modalBooking.texts.saveUpdates")
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    _vm._l(_vm.images, function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          class: _vm.isRegionBrazil()
                                            ? "col-4"
                                            : "col-6 mb-2",
                                        },
                                        [
                                          _c("new-upload-image", {
                                            attrs: {
                                              label: item.label,
                                              caption: item.caption,
                                              item: item,
                                              "key-item": item.keyItem,
                                              "file-upload-endpoint":
                                                item.endpoint,
                                              "file-upload-props":
                                                item.uploadProps,
                                            },
                                            on: {
                                              imageUpload: function ($event) {
                                                return _vm.onImageUpload($event)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.block_ui && _vm.data.status === "PENDING"
                        ? _c(
                            "b-alert",
                            { attrs: { show: "", variant: "warning" } },
                            [
                              _c("h4", [
                                _vm._v(
                                  " Estamos analisando as assinaturas, aguarde ... "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("driver-license-alert", {
            attrs: { license_expired_at: _vm.driverData.license_expired_at },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "row btn-container",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer",
        },
        [
          (_vm.data.status === "ACTIVE" || _vm.data.status === "CLOSED") &&
          _vm.data.id
            ? _c(
                "b-button",
                {
                  staticClass: "print_contract",
                  attrs: { disabled: _vm.block_ui, variant: "link" },
                  on: { click: _vm.handlePrintContract },
                },
                [
                  _vm.block_ui
                    ? _c("i", { staticClass: "fa fa-spin fa-refresh" })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$i18n.t("modalBooking.texts.printContract")) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          (_vm.data.status === "ACTIVE" || _vm.data.status === "CLOSED") &&
          _vm.data.id
            ? _c(
                "b-button",
                {
                  staticClass: "bg-warning",
                  on: { click: _vm.showDocumentsSection },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$i18n.t("drivers.labels.showDocuments")) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              attrs: { disabled: _vm.block_ui },
              on: { click: _vm.handleClose },
            },
            [_vm._v(" " + _vm._s(_vm.modal.cancel_button) + " ")]
          ),
          _vm.data.id && _vm.data.status === "ACTIVE"
            ? _c(
                "b-button",
                {
                  attrs: { disabled: _vm.block_ui, variant: "danger" },
                  on: { click: _vm.handleOpenCancelBookingModal },
                },
                [_vm._v(" " + _vm._s(_vm.modal.close_booking_button) + " ")]
              )
            : _vm._e(),
          _vm.data.status === "PENDING"
            ? _c(
                "b-button",
                {
                  attrs: { disabled: _vm.block_ui, variant: "danger" },
                  on: { click: _vm.handleCancelBooking },
                },
                [_vm._v(" " + _vm._s(_vm.modal.cancel_booking_button) + " ")]
              )
            : _vm._e(),
          !_vm.modal_data.status ||
          !_vm.data.car ||
          (_vm.canSaveBooking &&
            ["ACTIVE", "PENDING"].includes(_vm.modal_data.status))
            ? _c(
                "b-button",
                {
                  attrs: {
                    disabled:
                      _vm.block_ui ||
                      (!_vm.block_pending &&
                        _vm.isRegionBrazil() &&
                        !_vm.isValidOpenKind) ||
                      _vm.cannotSave ||
                      _vm.block_ui_by_driver_ocr,
                    title: _vm.block_ui_by_driver_ocr
                      ? "Voce precisa salvar os dados da CNH"
                      : "",
                    variant: "success",
                  },
                  on: { click: _vm.openCompareSignature },
                },
                [
                  _vm.block_ui
                    ? _c("i", { staticClass: "fa fa-spin fa-refresh" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.modal.save_button) + " "),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("modal-cancel-booking", {
        attrs: { "modal-data": _vm.modal_data },
        on: {
          closeModalCancelTransaction: _vm.handleCloseCancelBooking,
          confirmCloseBooking: _vm.handleReasonClosingBooking,
          errorCancelTransaction: _vm.handleCloseCancelBooking,
        },
        model: {
          value: _vm.modalCancelBooking,
          callback: function ($$v) {
            _vm.modalCancelBooking = $$v
          },
          expression: "modalCancelBooking",
        },
      }),
      _vm.isRegionBrazil() && _vm.data.status === "PENDING"
        ? _c("modal-fleet-compare-signatures", {
            ref: "fleetCompareSignatures",
            attrs: {
              "driver-document-url": _vm.getDriverDocumentUrl,
              "contract-url": _vm.getContractUrl,
              "booking-id": _vm.bookingSelect.id,
              "driver-id": _vm.bookingSelect.driver.id,
              "can-open-modal-on-load": false,
              "handle-yes": _vm.handleSubmit,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }