import { Region } from '@entities/region';

export const WORK_CITY_BR = Region.getWorkCitiesByCountry('BR');

export const BRAZILIAN_STATES = [
  { item: 'SP', name: 'São Paulo' },
  { item: 'AC', name: 'Acre' },
  { item: 'AL', name: 'Alagoas' },
  { item: 'AP', name: 'Amapá' },
  { item: 'AM', name: 'Amazonas' },
  { item: 'BA', name: 'Bahia' },
  { item: 'CE', name: 'Ceará' },
  { item: 'DF', name: 'Distrito Federal' },
  { item: 'ES', name: 'Espírito Santo' },
  { item: 'GO', name: 'Goiás' },
  { item: 'MA', name: 'Maranhão' },
  { item: 'MT', name: 'Mato Grosso' },
  { item: 'MS', name: 'Mato Grosso do Sul' },
  { item: 'MG', name: 'Minas Gerais' },
  { item: 'PA', name: 'Pará' },
  { item: 'PB', name: 'Paraíba' },
  { item: 'PR', name: 'Paraná' },
  { item: 'PE', name: 'Pernambuco' },
  { item: 'PI', name: 'Piauí' },
  { item: 'RJ', name: 'Rio de Janeiro' },
  { item: 'RN', name: 'Rio Grande do Norte' },
  { item: 'RS', name: 'Rio Grande do Sul' },
  { item: 'RO', name: 'Rondônia' },
  { item: 'RR', name: 'Roraima' },
  { item: 'SC', name: 'Santa Catarina' },
  { item: 'SE', name: 'Sergipe' },
  { item: 'TO', name: 'Tocantins' },
];

export const CNH_CATEGORIES = [
  { item: 'AB', name: 'AB' },
  { item: 'A', name: 'A' },
  { item: 'B', name: 'B' },
  { item: 'C', name: 'C' },
  { item: 'D', name: 'D' },
  { item: 'E', name: 'E' },
];
