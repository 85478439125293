var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "docsUpload",
        lazy: "",
        "no-fade": "",
        size: "lg",
        "no-close-on-backdrop": _vm.loading && _vm.isBrazil,
        "no-close-on-esc": _vm.loading && _vm.isBrazil,
        "hide-header-close": _vm.loading && _vm.isBrazil,
        "hide-footer": true,
        title: _vm.$t("drivers.labels.deliveryCars"),
      },
      on: { hide: _vm.handleClose, show: _vm.handleShow },
    },
    [
      !!_vm.booking.driver.id
        ? _c("driver-header", {
            attrs: { id: _vm.booking.driver.id, "reset-profile": false },
          })
        : _vm._e(),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _vm._v(" " + _vm._s(_vm.booking.plan.name) + " "),
              _c("br"),
              _vm.booking.car.fuel.length
                ? _c(
                    "div",
                    _vm._l(_vm.booking.car.fuel, function (obj, index) {
                      return _c("strong", { key: index }, [
                        _vm._v(" " + _vm._s(_vm.getFuelName(obj)) + " "),
                        _c("span", [_vm._v("/")]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(" " + _vm._s(_vm.$t("drivers.labels.board")) + " "),
              _c("br"),
              _c("strong", [
                _vm._v(" " + _vm._s(_vm.booking.car.license_plate) + " "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 mb-4" }, [
                _c("h5", [
                  _c("strong", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("drivers.labels.docsLocation")) + " "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm.loading
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.loading && _vm.isBrazil,
                              variant: "warning",
                            },
                          },
                          [
                            _c("h4", [
                              _vm._v(
                                " Estamos analisando as assinaturas, aguarde ... "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.signatureApprovedByIa
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-alert",
                          { attrs: { show: _vm.isBrazil, variant: "success" } },
                          [
                            _c("h4", [
                              _vm._v(" A assinatura já foi aprovada! "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.images, function (item, index) {
                return _c(
                  "div",
                  { key: index, class: _vm.isBrazil ? "col-4" : "col-6 mb-2" },
                  [
                    _c("new-upload-image", {
                      attrs: {
                        label: item.label,
                        caption: item.caption,
                        item: item,
                        "key-item": item.keyItem,
                        "file-upload-endpoint": item.endpoint,
                        "file-upload-props": item.uploadProps,
                      },
                      on: {
                        imageUpload: function ($event) {
                          return _vm.onImageUpload($event)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-center mb-4" },
            [
              _c(
                "b-button",
                {
                  staticClass: "font-weight-bold",
                  attrs: {
                    id: "driver-view-cancel-booking",
                    variant: "danger",
                    disabled: !_vm.enableSignatureCompare || _vm.loading,
                  },
                  on: { click: _vm.handleClose },
                },
                [_vm._v(" " + _vm._s(_vm.$t("modalBooking.words.close")) + " ")]
              ),
              _vm.loading && _vm.isBrazil
                ? _c(
                    "b-button",
                    {
                      staticClass: "font-weight-bold ml-3",
                      attrs: {
                        id: "driver-view-activate-booking",
                        variant: "success",
                        disabled:
                          !_vm.enableSignatureCompare || !_vm.signatureMatched,
                      },
                      on: { click: _vm.handleClose },
                    },
                    [
                      [
                        _c("b-spinner", {
                          staticClass: "mr-2",
                          attrs: { small: "" },
                        }),
                        _vm._v(" Analisando Assinaturas "),
                      ],
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-fleet-compare-signatures", {
        ref: "fleetCompareSignatures",
        attrs: {
          "driver-document-url": _vm.getDriverDocumentUrl,
          "contract-url": _vm.getContractUrl,
          "booking-id": _vm.booking.id,
          "driver-id": _vm.booking.driver.id,
          "can-open-modal-on-load": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }