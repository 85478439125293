<template v-if="$regions.isBrazil()">
  <ValidationObserver v-slot="{ valid }"
                      ref="observer"
                      tag="form"
                      @submit.prevent="submit()"
  >
    <b-card>
      <b-row slot="header">
        <b-col cols="8">
          <h4 class="mt-2">
            Dados da Habilitação de Motorista
          </h4>
        </b-col>

        <b-col cols="4" class="text-right mt-1">
          <b-button :disabled="blockUI || !valid"
                    class="mr-2"
                    variant="success"
                    @click.prevent="submit()"
          >
            {{ $t('drivers.buttons.save') }}
          </b-button>

          <b-button :disabled="blockUI"
                    variant="warning"
                    @click="reset()"
          >
            {{ $t('drivers.buttons.cancel') }}
          </b-button>
        </b-col>
      </b-row>

      <div v-show="loading" class="text-center text-primary my-2">
        <b-spinner class="align-middle" /><br><br>
        <strong>{{ loadingTitle }}<span class="dots" /></strong>
        <div v-if="valueProgress > 0 && valueProgress < 100">
          {{ valueProgress }}%
        </div>
      </div>
      <div v-show="!loading">
        <b-row>
          <b-form-group :label="$t('drivers.labels.licencePhoto')"
                        label-for="driver-license-photo"
                        class="col-md-12 col-sm-12"
          >
            <b-form-file id="driver-license-photo"
                         v-model="license_image"
                         accept="application/pdf,image/*"
                         placeholder="Escolha um arquivo ou arraste aqui"
                         class="mb-3"
                         @input="file => { license_image = file; onUpload(file); }"
            />
          </b-form-group>

          <b-form-group :label="$t('drivers.labels.licenceNumber') + ' *'"
                        label-for="driver-license-number"
                        class="col-md-3"
          >
            <ValidationProvider v-slot="{ errors }"
                                :rules="'required|cnhValid'"
                                :name="$t('drivers.labels.inputNameCnh')"
            >
              <b-form-input id="driver-license-number"
                            v-model="data.license_number"
                            type="text"
                            maxlength="11"
              />
              <span class="error text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            :label="$t('drivers.labels.category')"
            label-for="driver-license-category"
            class="col-md-3 col-sm-12"
          >
            <b-form-select id="driver-license-category"
                           v-model.trim="data.license_category"
                           :options="cnhCategories"
                           class="mb-3"
                           value-field="item"
                           text-field="name"
                           disabled-field="notEnabled"
            />
          </b-form-group>

          <b-form-group
            :label="$t('drivers.labels.uf')"
            label-for="driver-license-state"
            class="col-md-3 col-sm-12"
          >
            <b-form-select id="driver-license-state"
                           v-model="data.license_state"
                           :options="brazilianStates"
                           class="mb-3"
                           value-field="item"
                           text-field="name"
                           disabled-field="notEnabled"
            />
          </b-form-group>

          <b-form-group :label="$t('drivers.labels.points')"
                        label-for="driver-license-points"
                        class="col-md-3 col-sm-12"
          >
            <b-form-input id="driver-license-points"
                          v-model="data.license_points"
                          type="number"
            />
          </b-form-group>
        </b-row>

        <b-row>
          <b-form-group :label="$t('drivers.labels.issuanceDate')"
                        label-for="driver-license-issue-date"
                        class="col-md-3 col-sm-12"
          >
            <b-form-input id="driver-license-issue-date"
                          v-model="data.license_issued_at"
                          class="driver-license-issue-date"
                          type="date"
            />
          </b-form-group>

          <b-form-group
            :label="$t('drivers.labels.dueDate')+ ' *'"
            label-for="driver-license-expire-date"
            class="col-md-3 col-sm-12"
          >
            <ValidationProvider v-slot="{ errors, valid }"
                                :rules="'required|licenseExpirationValid'"
                                :name="$t('drivers.labels.dueDate')"
            >
              <b-form-input id="driver-license-expire-date"
                            v-model="data.license_expired_at"
                            class="driver-license-expired-date"
                            type="date"
                            :min="minDate()"
              />
              <span class="error text-danger">
                {{ errors[0] }}
              </span>
              <b-form-invalid-feedback id="driver-license-expire-date-feedback">
                {{ $t('drivers.texts.driverDocumentExpired') }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-row>
        <b-alert :show="blockBooking" class="text-center font-weight-bold" variant="warning">
          Voce precisa salvar os dados da habilitação para iniciar o booking!
        </b-alert>
      </div>
    </b-card>
  </ValidationObserver>
</template>

<script>
import APPROVE_DOCUMENTATION from '@graphql/driver-documents/mutations/new-approve-documentation.gql';
import UPDATE_DRIVER_CNH from '@graphql/driver-documents/mutations/update-cnh-doc-driver.gql';
import DRIVER_UPDATE from "@graphql/driver/mutations/update-profile.gql";
import { driverErrors } from "@utils/graphql-messages";
import { BRAZILIAN_STATES, CNH_CATEGORIES } from "@enums/signup";
import { format } from 'date-fns'
import { readFile, compressImage } from '@utils/image';
import axios from 'axios';

export default {
  name: "LicenseInfoEdit",
  props: {
    driverData: {
      type: Object,
      required: true,
    },
    enableDisableSaveButton: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      blockUI: true,
      data: null,
      license_image: [],
      brazilianStates: BRAZILIAN_STATES,
      cnhCategories: CNH_CATEGORIES,
      valueProgress: 0,
      image: null,
      loadingTitle: '',
      blockBooking: false,
    }
  },
  mounted() {
    this.getDriverData();
  },
  methods: {
    getDriverData() {
      this.data =  { ...this.driverData };
      this.loading = false;
      this.blockUI = false;
    },
    async setDriver(driverDocumentURL) {
      this.loadingTitle = 'Carregando'
      this.loading = true
      this.blockUI = true
      if (driverDocumentURL) {
        try {
          const { data } = await this.$fleetRegulationProvider.post(`drivers/details`, {
            driverDocumentURL,
          });
          this.data.license_number = data.cnhNumber.padStart(11, '0');
          this.data.license_category = data.category.replace('/', '').replace(' ', '').replace(',', '');
          this.data.license_issued_at = data.issueDate ? this.formatDate(data.issueDate) : null;
          this.data.license_expired_at = data.expirationDate ? this.formatDate(data.expirationDate) : null;
          this.data.license_state = this.getMostSimilar(data.state);
        } catch(error) {
          this.blockUI = true;
          this.data.license_number = null;
          this.data.license_category = null;
          this.data.license_issued_at = null;
          this.data.license_expired_at = null;
          this.data.license_state = null;
          this.$swal({
            type: 'error',
            title: 'Ocorreu um erro',
            text: 'Não conseguimos carregar os dados da Habilitação do Motorista',
            showConfirmButton: true,
            timer: 0,
          });
        } finally {
          this.loading = false;
          this.blockUI = false;
        }
      }
    },
    formatDate(date) {
      const [day, month, year] = date.split('/');
      return format(new Date(year, month - 1, day), 'YYYY-MM-DD');
    },
    getMostSimilar(data_state) {
      if (!data_state) return null;

      let state = this.brazilianStates.find((state) => {
        if (state.item === data_state.toUpperCase()) return state.item;
      });

      if (state) return state.item;
      state = this.brazilianStates.reduce((maisProximo, state) => {
        const distance = this.mostSimilar(data_state.toLowerCase(), state.name.toLowerCase());
        return distance < maisProximo.distance ? { state: state.item, distance } : maisProximo;
      }, { state: null, distance: Infinity });

      return state.state;
    },
    mostSimilar(a, b) {
      let distancia = 0;
      const maxLength = Math.max(a.length, b.length);

      for (let i = 0; i < maxLength; i++) {
        if (a[i] !== b[i]) {
          distancia++;
        }
      }
      return distancia;
    },
    async transformPdfToImage(pdfBase64File, driverId, config) {
      this.loadingTitle = 'Convertendo PDF para imagem'
      const formData = new FormData();
      formData.append('data', pdfBase64File.split(',')[1]);
      formData.append('documentType', 'cnh_pdf');
      formData.append('driverId', driverId || 'driverId');
      const res = await axios.post(`${process.env.KOVI_API_URL}/docs`, formData, config).then(response => response.data);
      return `data:image/png;base64,${res.image}`;
    },
    async onUpload(file) {
      this.loadingTitle = 'Carregando arquivo';
      let base64File = await readFile(file);
      let base64FileOriginalPdf = null;
      this.loading = true;

      const config = {
        onUploadProgress: progressEvent => {
          this.valueProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        },
      };
      if (file.type === 'application/pdf') {
        base64FileOriginalPdf = base64File;
        base64File = await this.transformPdfToImage(base64File, this.data.id, config);

        if (!base64File) {
          this.loading = false;
          return;
        }
        this.image = base64File;
      } else {
        this.loadingTitle = 'Comprimindo imagem';
        this.image = await compressImage(base64File);
      }
      let fd = new FormData();
      fd.append('data', this.image);
      fd.append('documentType', 'cnh_photo');
      fd.append('driverId', this.data.id);
      if (base64FileOriginalPdf) {
        fd.append('originalPdf', base64FileOriginalPdf);
      }

      this.loadingTitle = 'Salvando imagem';
      axios
        .post(`${process.env.KOVI_API_URL}/docs`, fd, config)
        .then(response => {
          this.data.imageUrl = response.data.body.url;
          this.data.imageId = response.data.body.id;
          this.saveUrl();
        })
        .catch(err => {
          console.log(err);
          this.valueProgress = 99;
        })
    },
    saveUrl() {
      return this.$apollo
        .mutate({
          mutation: APPROVE_DOCUMENTATION,
          variables: {
            documentId: this.data?.imageId,
          },
        })
        .then(() => {
          return this.$apollo
            .mutate({
              mutation: UPDATE_DRIVER_CNH,
              variables: {
                driverId: this.data.id,
                documentId: this.data?.imageId,
              },
            })
            .then(() => {
              this.valueProgress = 0;
              this.enableDisableSaveButton(true);
              this.blockBooking = true;
              this.setDriver(this.data?.imageUrl);
            })
            .catch(() => {
              this.loading = false;
              return Promise.reject('Não foi possível finalizar o processo 2.');
            });
        })
        .catch(() => {
          this.loading = false;
          return Promise.reject('Não foi possível finalizar o processo 1.');
        });
    },
    reset() {
      this.data =  { ...this.driverData };
    },
    submit() {
      this.data.license_points = Number(this.data.license_points)

      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('drivers.texts.areYouSure'),
        text: this.$t('drivers.texts.updateDriver'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('drivers.buttons.confirm'),
        cancelButtonText: this.$t('drivers.buttons.cancel'),
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.loadingTitle = 'Salvando';
          this.loading = true;
          this.$apollo
            .mutate({
              mutation: DRIVER_UPDATE,
              variables: { ...this.data },
            })
            .then(() => {
              this.enableDisableSaveButton(false);
              this.blockBooking = false;
              confirmationWindow({
                type: 'success',
                title: this.$t('drivers.texts.updateSuccess'),
                showConfirmButton: false,
                timer: 2500,
              })
            })
            .catch(error => {
              const errorMsg = driverErrors(error.message);

              this.$swal({
                type: 'error',
                title: this.$t('drivers.texts.errorSave'),
                text: errorMsg,
                showConfirmButton: false,
                timer: 2500,
              }).then(() => {
                this.data =  { ...this.driverData };
              });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.data =  { ...this.driverData };
        }
      });
    },
    minDate() {
      const today = new Date();
      const pastDate = new Date(today.setDate(today.getDate() - 30));
      return pastDate.toISOString().split('T')[0];
    },
  },
};
</script>

<style scoped>
.error {
  font-size: 12px;
}

.dots::after {
  content: "";
  animation: dots 1.5s steps(4, end) infinite;
}

@keyframes dots {
  0% { content: ""; }
  25% { content: "."; }
  50% { content: ".."; }
  75% { content: "..."; }
}
</style>
