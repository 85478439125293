var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationObserver", {
    ref: "observer",
    attrs: { tag: "form" },
    on: {
      submit: function ($event) {
        $event.preventDefault()
        return _vm.submit()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ valid }) {
          return [
            _c(
              "b-card",
              [
                _c(
                  "b-row",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c("b-col", { attrs: { cols: "8" } }, [
                      _c("h4", { staticClass: "mt-2" }, [
                        _vm._v(" Dados da Habilitação de Motorista "),
                      ]),
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "text-right mt-1", attrs: { cols: "4" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              disabled: _vm.blockUI || !valid,
                              variant: "success",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.submit()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("drivers.buttons.save")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.blockUI,
                              variant: "warning",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reset()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("drivers.buttons.cancel")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "text-center text-primary my-2",
                  },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _c("br"),
                    _c("br"),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.loadingTitle)),
                      _c("span", { staticClass: "dots" }),
                    ]),
                    _vm.valueProgress > 0 && _vm.valueProgress < 100
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.valueProgress) + "% "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading,
                        expression: "!loading",
                      },
                    ],
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-12 col-sm-12",
                            attrs: {
                              label: _vm.$t("drivers.labels.licencePhoto"),
                              "label-for": "driver-license-photo",
                            },
                          },
                          [
                            _c("b-form-file", {
                              staticClass: "mb-3",
                              attrs: {
                                id: "driver-license-photo",
                                accept: "application/pdf,image/*",
                                placeholder:
                                  "Escolha um arquivo ou arraste aqui",
                              },
                              on: {
                                input: (file) => {
                                  _vm.license_image = file
                                  _vm.onUpload(file)
                                },
                              },
                              model: {
                                value: _vm.license_image,
                                callback: function ($$v) {
                                  _vm.license_image = $$v
                                },
                                expression: "license_image",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3",
                            attrs: {
                              label:
                                _vm.$t("drivers.labels.licenceNumber") + " *",
                              "label-for": "driver-license-number",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|cnhValid",
                                name: _vm.$t("drivers.labels.inputNameCnh"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "driver-license-number",
                                            type: "text",
                                            maxlength: "11",
                                          },
                                          model: {
                                            value: _vm.data.license_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "license_number",
                                                $$v
                                              )
                                            },
                                            expression: "data.license_number",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error text-danger" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(errors[0]) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              label: _vm.$t("drivers.labels.category"),
                              "label-for": "driver-license-category",
                            },
                          },
                          [
                            _c("b-form-select", {
                              staticClass: "mb-3",
                              attrs: {
                                id: "driver-license-category",
                                options: _vm.cnhCategories,
                                "value-field": "item",
                                "text-field": "name",
                                "disabled-field": "notEnabled",
                              },
                              model: {
                                value: _vm.data.license_category,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "license_category",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "data.license_category",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              label: _vm.$t("drivers.labels.uf"),
                              "label-for": "driver-license-state",
                            },
                          },
                          [
                            _c("b-form-select", {
                              staticClass: "mb-3",
                              attrs: {
                                id: "driver-license-state",
                                options: _vm.brazilianStates,
                                "value-field": "item",
                                "text-field": "name",
                                "disabled-field": "notEnabled",
                              },
                              model: {
                                value: _vm.data.license_state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "license_state", $$v)
                                },
                                expression: "data.license_state",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              label: _vm.$t("drivers.labels.points"),
                              "label-for": "driver-license-points",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "driver-license-points",
                                type: "number",
                              },
                              model: {
                                value: _vm.data.license_points,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "license_points", $$v)
                                },
                                expression: "data.license_points",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              label: _vm.$t("drivers.labels.issuanceDate"),
                              "label-for": "driver-license-issue-date",
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "driver-license-issue-date",
                              attrs: {
                                id: "driver-license-issue-date",
                                type: "date",
                              },
                              model: {
                                value: _vm.data.license_issued_at,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "license_issued_at", $$v)
                                },
                                expression: "data.license_issued_at",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              label: _vm.$t("drivers.labels.dueDate") + " *",
                              "label-for": "driver-license-expire-date",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|licenseExpirationValid",
                                name: _vm.$t("drivers.labels.dueDate"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("b-form-input", {
                                          staticClass:
                                            "driver-license-expired-date",
                                          attrs: {
                                            id: "driver-license-expire-date",
                                            type: "date",
                                            min: _vm.minDate(),
                                          },
                                          model: {
                                            value: _vm.data.license_expired_at,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "license_expired_at",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.license_expired_at",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error text-danger" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(errors[0]) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "driver-license-expire-date-feedback",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "drivers.texts.driverDocumentExpired"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-alert",
                      {
                        staticClass: "text-center font-weight-bold",
                        attrs: { show: _vm.blockBooking, variant: "warning" },
                      },
                      [
                        _vm._v(
                          " Voce precisa salvar os dados da habilitação para iniciar o booking! "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }